export const ORDER_STATUS_PAID = "paid";
export const ORDER_STATUS_CANCELLED = "cancelled";
export const ORDER_STATUS_OVER_PAID = "over-paid";
export const ORDER_STATUS_REFUNDED = "refunded";
export const ORDER_STATUS_NOT_REFUNDED_REQUESTED = "-requested";
export const ORDER_STATUS_OVER_REFUNDED = "over-refunded";
export const ORDER_STATUS_PARTIALLY_REFUNDED = "partially-refunded";
export const ORDER_STATUS_PLACED = "placed";
export const ORDER_STATUS_UNDER_REFUNDED = "under-refunded";
export const ORDER_STATUS_UNDER_PAID = "under-paid";

export const ORDER_STATUS = {
  [ORDER_STATUS_PAID]: "결제완료",
  [ORDER_STATUS_CANCELLED]: "취소",
  [ORDER_STATUS_OVER_PAID]: "초과결제",
  [ORDER_STATUS_REFUNDED]: "환불완료",
  [ORDER_STATUS_OVER_REFUNDED]: "초과환불",
  [ORDER_STATUS_PARTIALLY_REFUNDED]: "부분환불",
  [ORDER_STATUS_PLACED]: "결제대기",
  [ORDER_STATUS_UNDER_REFUNDED]: "환불진행중",
  [ORDER_STATUS_UNDER_PAID]: "결제중",
};

export const ORDER_SEARCH_OPTION_STATUS = "status";
export const ORDER_SEARCH_OPTION_NAME = "search:customer.name.full";
export const ORDER_SEARCH_OPTION_EMAIL = "search:customer.email";
export const ORDER_SEARCH_OPTION_MOBILE = "search:customer.mobile";
export const ORDER_SEARCH_OPTION_USER_ID = "search:customer.userId";
export const ORDER_SEARCH_OPTION_CREATED = "createdAt>";
export const ORDER_SEARCH_OPTION_CREATED_END = "createdAt<";
export const ORDER_SEARCH_OPTION_IDS = "ids";
export const ORDER_SEARCH_OPTION_RECENT = "-createdAt";
export const ORDER_SEARCH_PRODUCT_NAME = "search:products.name";
export const ORDER_ITEM_KNOLLO_VENDOR = process.env.NEXT_PUBLIC_ORDER_VENDOR;

// 배송 준비 단계 api payload
export const ORDER_STATUS_FULFILLMENT_PENDING = `subscription,done,synced,status,tags,fulfillments,address.shipping,total,customer,createdAt,items,shipments`;
// items.vendor,items.type,items.quantity,items.product,items.total,items.bundleItems,items._id,items.quantity,items.shippingMethod,total

export const ORDER_PARAMS = {
  status: "주문상태",
  ids: "주문ID",
  "createdAt>": "생성일",
  "createdAt<": "createdAt<",
  "createdAt>": "createdAt>",
  "updatedAt>": "updatedAt>",
  "updatedAt<": "updatedAt<",
  "total>": "total>",
  "total<": "total<",
  orderNumber: "주문번호",
  "search:customer.email": "이메일",
  "search:customer.mobile": "휴대폰",
  name: "name",
  receiver: "수취인",
  "search:customer.userId": "유저ID",
  receiverMobile: "receiverMobile",
  receiverAddress: "receiverAddress",
  receiverAddressDetail: "receiverAddressDetail",
  receiverZipCode: "receiverZipCode",
  "search:customer.name.full": "이름",
  coupon: "coupon",
  couponCode: "couponCode",
  couponType: "couponType",
  "couponDiscount>": "couponDiscount>",
  "couponDiscount<": "couponDiscount<",
  couponDiscountType: "couponDiscountType",
  "couponDiscountType>": "couponDiscountType>",
  "couponDiscountType<": "couponDiscountType<",
  "couponDiscountAmount>": "couponDiscountAmount>",
  "couponDiscountAmount<": "couponDiscountAmount<",
  couponDiscountAmountType: "couponDiscountAmountType",
  "couponDiscountAmountType>": "couponDiscountAmountType>",
  "couponDiscountAmountType<": "couponDiscountAmountType<",
  couponDiscountAmount: "couponDiscountAmount",
  "couponDiscountAmount>": "couponDiscountAmount>",
  "couponDiscountAmount<": "couponDiscountAmount<",
  couponDiscountAmountType: "couponDiscountAmountType",
  "couponDiscountAmountType>": "couponDiscountAmountType>",
  "couponDiscountAmountType<": "couponDiscountAmountType<",
  couponDiscountAmount: "couponDiscountAmount",
  "couponDiscountAmount>": "couponDiscountAmount>",
  "couponDiscountAmount<": "couponDiscountAmount<",
  couponDiscountAmountType: "couponDiscountAmountType",
  "couponDiscountAmountType>": "couponDiscountAmountType>",
  "couponDiscountAmountType<": "couponDiscountAmountType<",
  couponDiscountAmount: "couponDiscountAmount",
  "couponDiscountAmount>": "couponDiscountAmount>",
  "couponDiscountAmount<": "couponDiscountAmount<",
  couponDiscountAmountType: "couponDiscountAmountType",
  "couponDiscountAmountType>": "couponDiscountAmountType>",
  refundStatus: "환불상태",
  received: "수령여부",
  sort: "정렬",
  vendor: "벤더",
  couponSlugs: "쿠폰Slug",
};
